"use client";

import { useRef, useState } from "react";
import { Combobox } from "@headlessui/react";

import Icon from "@/components/atoms/Icon";
import TextInput from "@/components/forms/controls/TextInput";
import { cn } from "@/helpers/className";
import type { SearchHistoryEntry } from "@/types/generic";

interface SearchQueryInputProps {
  className?: string;
  i18n: {
    clear_recent_searches: string;
    label: string;
    new: string;
    placeholder: string;
    recent_searches: string;
  };
  searchHistory?: boolean;
  searchHistoryBaseURL?: string;
}

export const SearchQueryInput = ({
  className,
  i18n,
  searchHistory = true,
  searchHistoryBaseURL,
}: SearchQueryInputProps) => {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLElement>(null);

  let queryHistory: SearchHistoryEntry[] = [];

  if (
    searchHistory &&
    typeof window !== "undefined" &&
    localStorage.getItem("jobSearchHistory")
  ) {
    queryHistory = JSON.parse(localStorage.getItem("jobSearchHistory"));
  }

  const clearSearchHistory = () => {
    localStorage.removeItem("jobSearchHistory");
    queryHistory = [];
    setOpen(false);
  };

  return (
    <div className={cn("relative flex", className)}>
      <Combobox
        as={"div"}
        className="relative flex w-full"
        onChange={(value) => {
          const url = new URL(
            `${window.location.origin}${searchHistoryBaseURL ?? window.location.pathname}`,
          );

          if (value.query) {
            url.searchParams.set("query", value.query);
          }
          if (value.city) {
            url.searchParams.set("city", value.city);
          }
          if (value.max_distance) {
            url.searchParams.set("max_distance", value.max_distance);
          }

          window.location.href = url.toString();
        }}
        ref={ref}
      >
        <TextInput
          label={i18n.label}
          placeholder={i18n.placeholder}
          name="query"
          className="flex w-full items-center"
          type="search"
          inputClassname={cn("form-input-lg ring-0 focus:ring-0 rounded-lg")}
          icon={"magnify"}
          hiddenLabel={true}
          onFocus={() => setOpen(true)}
          onBlur={(e) => {
            if (!ref?.current?.contains(e?.relatedTarget as Node)) {
              setOpen(false);
            }
          }}
          onChange={() => setOpen(false)}
          onInput={() => setOpen(false)}
          autoComplete={"off"}
        />

        {queryHistory.length > 0 && (
          <div
            className={cn(
              "absolute inset-x-0 top-full z-30 max-h-72 min-w-[300px] scroll-py-2 overflow-y-auto border bg-white px-6 py-4 pt-1 text-sm text-gray-800 shadow-lg",
              open ? "block" : "hidden",
            )}
          >
            <Combobox.Options static>
              <li className="border-b-none mb-2 flex items-center justify-between gap-1 py-1">
                <span className="text-xs text-gray-400 sm:text-sm">
                  {i18n.recent_searches}
                </span>
                <button
                  className="link text-xs sm:text-sm"
                  onClick={clearSearchHistory}
                  type="button"
                >
                  {i18n.clear_recent_searches}
                </button>
              </li>
              {queryHistory.reverse().map((item, index) => (
                <Combobox.Option
                  key={index}
                  value={item}
                  className={({ active }) =>
                    cn(
                      "flex cursor-pointer select-none items-center gap-2 py-2 transition-colors",
                      active && "text-green",
                    )
                  }
                >
                  <Icon name={"history"} className="h-5 w-5 text-green" />

                  <div className="flex items-center gap-1">
                    {item.query && (
                      <span className="text-base">{item.query}</span>
                    )}
                    {item.city && (
                      <div className="text-sm">
                        <span>
                          - {item.city}
                          {item.max_distance && (
                            <span className="pl-1 text-gray-400">
                              (&plusmn;{item.max_distance}&thinsp;km)
                            </span>
                          )}
                        </span>
                      </div>
                    )}
                    {item?.newCount ? (
                      <span className="ml-auto text-sm">
                        {item.newCount} {i18n.new}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </Combobox.Option>
              ))}
            </Combobox.Options>
          </div>
        )}
      </Combobox>
    </div>
  );
};
